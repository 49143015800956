<template>
  <v-data-table :headers="[...headers, { text: '', value: 'actions', sortable: false }]"
                :items="items"
                :loading="loading"
                v-bind="$attrs"
                class="elevation-1">
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>{{ itemName }}</v-toolbar-title>

        <v-spacer />

        <v-alert v-if="error"
                 type="error">
          ERROR: {{ errorMessage }}
        </v-alert>

        <v-spacer />

        <v-btn icon
               class="mr-2"
               @click="loadItems">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-dialog v-model="dialog"
                  max-width="500px">
          <template #activator="{ on, attrs }">
            <v-btn color="primary"
                   v-bind="attrs"
                   v-on="on">
              Agregar {{ itemName }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col v-for="(item) in headers"
                           :key="item.value"
                           cols="12"
                           sm="6">
                      <v-text-field v-if="item.value === 'id'"
                                    v-model.number="editedItem[item.value]"
                                    :label="item.text"
                                    :rules="item.rules || []" />
                      <v-text-field v-else
                                    v-model="editedItem[item.value]"
                                    :label="item.text"
                                    :rules="item.rules || []" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"
                     text
                     @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1"
                     text
                     @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete"
                  max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Confirma eliminar este {{ itemName.toLowerCase() }}?
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"
                     text
                     @click="closeDelete">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1"
                     text
                     @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template #item.actions="{ item }">
      <v-icon class="mr-2"
              @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import { cloneDeep } from 'lodash';

  export default {
    name: 'ABMTable',

    props: {
      itemName: {
        type: String,
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {},
    }),

    computed: {
      items() {
        return [];
      },
      loading() {
        return false;
      },
      error() {
        return null;
      },
      errorMessage() {
        return this.error.response?.data || this.error.request || this.error.message;
      },
      formTitle() {
        return (this.editedIndex === -1 ? 'Agregar ' : 'Editar ') + this.itemName;
      },
      defaultItem() {
        return this.headers.reduce((acc, cur) => {
          acc[cur.value] = cur.default || null;
          return acc;
        }, {});
      },
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },

    created() {
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    mounted() {
      this.loadItems();
    },

    methods: {
      loadItems: function() {},
      postItems: function() {},

      editItem(item) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem(item) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      deleteItemConfirm() {
        const data = cloneDeep(this.items);
        data.splice(this.editedIndex, 1);
        this.postItems({ data });
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.$refs.form.resetValidation();
        });
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.$refs.form.resetValidation();
        });
      },

      save() {
        const data = cloneDeep(this.items);
        if (this.editedIndex > -1) {
          Object.assign(data[this.editedIndex], this.editedItem);
        } else {
          data.push(this.editedItem);
        }
        this.postItems({ data });
        this.close();
      },
    },
  };
</script>

<style scoped>

</style>
