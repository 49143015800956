<template>
  <v-data-table :items="avisos"
                :headers="headers"
                :loading="loading"
                :search="search"
                disable-pagination
                hide-default-footer
                loading-text="Cargando avisos..."
                no-data-text="No hay avisos disponibles para listar"
                no-results-text="No se encontraron resultados para su búsqueda"
                class="elevation-1"
                v-bind="$attrs">
    <template #top>
      <v-container>
        <v-row justify="end"
               align="center"
               dense>
          <v-col cols="auto">
            <v-text-field v-model="search"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          single-line
                          hide-details
                          dense />
          </v-col>
          <v-col cols="auto">
            <v-btn icon
                   class="mx-2"
                   @click="$emit('reload')">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #item.actions="{ item }">
      <v-icon @click="$emit('edit-aviso', item)">
        mdi-pencil
      </v-icon>
    </template>

    <template #foot>
      <tfoot v-if="avisos.length > 0">
        <tr>
          <td :colspan="headers.length" class="px-0">
            <v-container fluid class="py-3">
              <v-row align="end"
                     justify="space-between">
                <template v-if="hayMasElementos">
                  <v-col md="4" class="d-none d-md-block" />
                  <v-col cols="6"
                         md="4"
                         class="text-center">
                    <v-btn color="primary"
                           height="25px"
                           :loading="loading"
                           @click="$emit('load-mas-avisos', fetchSize)">
                      Cargar {{ fetchSize }} avisos más
                    </v-btn>
                  </v-col>
                  <v-col cols="6"
                         md="4"
                         class="text-right">
                    <div class="text-overline font-italic">
                      Tamaño de carga
                    </div>
                    <v-btn-toggle :value="2" mandatory>
                      <v-btn x-small @click="fetchSize = 10">
                        10
                      </v-btn>
                      <v-btn x-small @click="fetchSize = 20">
                        20
                      </v-btn>
                      <v-btn x-small @click="fetchSize = 50">
                        50
                      </v-btn>
                      <v-btn x-small @click="fetchSize = 100">
                        100
                      </v-btn>
                      <v-btn x-small @click="fetchSize = 200">
                        200
                      </v-btn>
                      <v-btn x-small @click="fetchSize = 500">
                        500
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </template>
                <v-col v-else
                       class="text-center text-caption font-italic">
                  Fin de la lista. No hay más avisos para cargar.
                </v-col>
              </v-row>
            </v-container>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: 'AvisosList',

    props: {
      avisos: {
        type: Array,
        required: true,
      },
      headers: {
        type: Array,
        default: function() {
          return [
            { text: 'Fecha publicación', value: 'fecha_publicacion' },
            { text: 'Tipo', value: 'tipo_aviso' },
            { text: 'Nombre del difunto', value: 'nombre_difunto' },
            { text: 'Avisador', value: 'avisador_tipo' },
            { text: 'Estado', value: 'estado' },
            { text: '', value: 'actions', sortable: false },
          ];
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      hayMasElementos: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      search: '',
      fetchSize: 50,
    }),
  };
</script>

<style scoped lang="scss">
.v-data-table {
  border-radius: 0;
}
</style>
