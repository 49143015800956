/*
* Copiado de @vuetify/vuex-cognito-module con algunos agregados propios.
* Principal motivo para copiarlo en lugar de usarlo es que la clase Auth estaba complicando si se usaba
* desde varios lugares (perdía configuración, etc, como si fueran dos instancias distintas).
* */

import Amplify, { Auth } from 'aws-amplify';

const state = () => ({
    session: {},
    user: {},
});

const mutations = {
    setUser: (state, user) => {
        state.user = { ...user };
        state.session = state.user.signInUserSession;
    },
};

const getters = {
    // TODO: ensure best method to verify this
    isLoggedIn: (store = {}) => {
        const session = store.session;
        if (!session) return false;
        const accessToken = session.accessToken;
        if (!accessToken) return false;
        const hasToken = accessToken.jwtToken;
        const isActive = new Date(accessToken.payload.exp * 1000) > new Date();
        const isMe = accessToken.payload.username === store.user.username;
        return hasToken && isActive && isMe;
    },
    session: (store = {}) =>
        'session' in store && Object.keys(store.session).length !== 0
            ? store.session
            : false,
    userSub: (store = {}) => {
        if (store.user && store.user.attributes) {
            return store.user.attributes.sub;
        } else if (store.user && store.user.userSub) {
            return store.user.userSub;
        } else {
            return false;
        }
    },
    username: (store = {}) => store.user?.username ?? false,
    userAttributes: (store = {}) =>
        store.user && store.user.attributes
            ? store.user.attributes
            : false,
    userGroups: (store = {}) =>
        store.session &&
        store.session.accessToken &&
        store.session.accessToken.payload &&
        store.session.accessToken.payload['cognito:groups']
            ? store.session.accessToken.payload['cognito:groups']
            : false,
};

const actions = {
    fetchSession: ({ commit }) =>
        new Promise((resolve, reject) => {
            Auth.currentSession().then(session => {
                Auth.currentUserPoolUser()
                    .then(user => {
                        commit('setUser', user);
                        resolve(session);
                    }).catch(reject);
            }).catch(reject);
        }),
    fetchJwtToken: () =>
        new Promise((resolve, reject) => {
            Auth.currentSession().then(session => {
                resolve(session.getAccessToken().getJwtToken());
            }).catch(reject);
        }),
    signInUser: ({ commit }, credentials) =>
        new Promise((resolve, reject) => {
            Auth.signIn(credentials.username, credentials.password).then((user) => {
                commit('setUser', user);
                resolve(user);
            }).catch(reject);
        }),
    answerCustomChallenge: ({ commit }, credentials) =>
        new Promise((resolve, reject) => {
            Auth.sendCustomChallengeAnswer(credentials.user, credentials.answer).then((user) => {
                commit('setUser', user);
                resolve(user);
            }).catch(reject);
        }),
    registerUser: ({ commit }, credentials) =>
        new Promise((resolve, reject) => {
            // TODO: Ensure I'm attribute agnostic
            Auth.signUp({
                username: credentials.username,
                password: credentials.password,
                attributes: credentials.attributes,
            }).then(user => {
                commit('setUser', user);
                resolve(user);
            }).catch(reject);
        }),
    confirmUser: (_, data) =>
        new Promise((resolve, reject) => {
            Auth.confirmSignUp(data.username, data.code)
                .then(resolve)
                .catch(reject);
        }),
    resendConfirmation: (_, data) =>
        new Promise((resolve, reject) => {
            Auth.resendSignUp(data.username)
                .then(resolve)
                .catch(reject);
        }),
    forgotPassword: (_, data) =>
        new Promise((resolve, reject) => {
            Auth.forgotPassword(data.username)
                .then(resolve)
                .catch(reject);
        }),
    changePassword: (_, data) =>
        new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword)
                .then(resolve)
                .catch(reject);
        }),
    signOut: ({ commit, getters }) =>
        new Promise((resolve, reject) => {
            if (!getters.isLoggedIn) {
                reject(new Error('User not logged in.'));
            }
            Auth.signOut()
                .then(result => {
                    commit('setUser', {});
                    resolve(result);
                })
                .catch(reject);
            if (localStorage) localStorage.removeItem('USER');
        }),
    init(_, config) {
        if (![
            'userPoolId',
            'userPoolWebClientId',
            'region',
        ].every(opt => Boolean(config[opt]))) {
            throw new Error('userPoolId, userPoolWebClientId and region are required in the config object.');
        }
        Amplify.configure({ Auth: config });
    },
    //
    // -- agregados:
    //
    completeNewPassword: ({ commit }, { user, newPassword, requiredAttributes = {} }) =>
        new Promise((resolve, reject) => {
            Auth.completeNewPassword(user, newPassword, requiredAttributes)
                .then((user) => {
                    commit('setUser', user);
                    resolve(user);
                })
                .catch(reject);
        }),
    fetchCredentials: () =>
        new Promise((resolve, reject) => {
            Auth.currentCredentials()
                .then(credentials => {
                    resolve(Auth.essentialCredentials(credentials));
                }).catch(reject);
        }),
};


export default (store, config, namespace = 'cognito', vuexModuleOptions = {}) => {
    store.registerModule(namespace, {
        namespaced: true,
        state,
        mutations,
        getters,
        actions,
    }, vuexModuleOptions);

    store.dispatch(`${namespace}/init`, config);
};
