import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins';
import { sync } from 'vuex-router-sync';
import { Settings } from 'luxon';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.config.performance = true;

// Vue.prototype.$log = console.log.bind(console);

Settings.defaultZone = 'America/Montevideo';
Settings.defaultLocale = 'es-uy';

sync(store, router);

const EO = [
    '190.64.78.202', // EO Guatemala
    '190.64.78.203', // EO Guatemala
    '190.64.78.204', // EO Guatemala
    '190.64.78.205', // EO Guatemala
    '190.64.78.206', // EO Guatemala
    '190.64.82.102', // EO Ejido
    '201.217.128.202', // EO mudanza 2023
];

axios.get('https://api.ipify.org')
     .then(function(response) {
         // console.log(`${response.data} - ${process.env.VUE_APP_RESTRICT_IP}`);
         if (process.env.VUE_APP_RESTRICT_IP === 'false' || EO.includes(response.data)) {
             new Vue({
                 router,
                 store,
                 vuetify,
                 render: h => h(App),
             }).$mount('#app');
         } else {
             document.getElementById('app').innerHTML = '<h3>Acceso no permitido</h3>';
         }
     })
     .catch(function() {
         document.getElementById('app').innerHTML = '<h3>Acceso no permitido</h3>';
     });
