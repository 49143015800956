import store from '@/store';
import Vapi from 'vuex-rest-api';

const authorization = () => ({ Authorization: `Bearer ${store.get('cognito/session').getAccessToken().getJwtToken()}` });

const globales = new Vapi({
    baseURL: process.env.VUE_APP_AVISOS_API_BASE_URL,
    state: {
        cementerios: [],
        velatorios: [],
        funerarias: [],
        configuracion: {},
    },
})
    .get({
        action: 'getCementerios',
        property: 'cementerios',
        path: '/cementerios',
    })
    .put({
        action: 'putCementerios',
        property: 'cementerios',
        path: '/cementerios',
        headers: authorization,
    })
    .get({
        action: 'getVelatorios',
        property: 'velatorios',
        path: '/velatorios',
    })
    .put({
        action: 'putVelatorios',
        property: 'velatorios',
        path: '/velatorios',
        headers: authorization,
    })
    .get({
        action: 'getFunerarias',
        property: 'funerarias',
        path: '/funerarias',
        headers: authorization,
    })
    .put({
        action: 'putFunerarias',
        property: 'funerarias',
        path: '/funerarias',
        headers: authorization,
    })
    .get({
        action: 'getConfiguracion',
        property: 'configuracion',
        path: '/config',
        headers: authorization,
    })
    .put({
        action: 'putConfiguracion',
        property: 'configuracion',
        path: '/config',
        headers: authorization,
    })
    .getStore({
        namespaced: true,
        // createStateFn: true,
    });

export default globales;
