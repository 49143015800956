import Vapi from 'vuex-rest-api';
import store from '@/store';
import { make } from 'vuex-pathify';

export const ESTADOS = [
    { value: 'INGRESADO', text: 'INGRESADO' },
    { value: 'APROBADO', text: 'APROBADO' },
    { value: 'PUBLICADO', text: 'PUBLICADO' },
    { value: 'CONFIRMADO', text: 'CONFIRMADO' },
    { value: 'FACTURADO', text: 'FACTURADO' },
    { value: 'RECHAZADO', text: 'RECHAZADO' },
];
export const FORMAS_PAGO = [
    // { value: 'ABITAB', text: 'Abitab' },
    { value: 'TRANSFERENCIA', text: 'Transferencia bancaria' },
];
// const FORMATOS = ['1col', '2col'];
export const SIMBOLOS = [
    { value: 'Cruz', text: 'Cruz', unicode: '\u271D', icon: 'mdi-cross' },
    { value: 'Estrella', text: 'Estrella', unicode: '\u2721', icon: 'mdi-star-david' },
    { value: 'Ninguno', text: 'Ninguno', icon: '' },
];
export const TIPOS_AVISADOR = [
    { value: 'PARTICULAR', text: 'Persona' },
    { value: 'EMPRESA', text: 'Empresa' },
    { value: 'FUNERARIA', text: 'Funeraria' },
];
export const TIPOS_AVISO = [
    { value: 'Sepelio', text: 'Sepelio' },
    { value: 'Participación', text: 'Participación' },
    { value: 'Fallecimiento', text: 'Fallecimiento' },
    { value: 'In Memoriam', text: 'In Memoriam' },
    { value: 'Misa', text: 'Misa' },
];


const authorization = () => ({ Authorization: `Bearer ${store.get('cognito/session').getAccessToken().getJwtToken()}` });

const setAvisos = (state, payload /*, axios, { params, data }*/) => {
    state.avisos = payload.data.items;
    state.avisos.count = payload.data.count;
    state.avisos.scannedCount = payload.data.scannedCount;
    state.avisos.lastEvaluatedKey = payload.data.lastEvaluatedKey;
};

const avisos = new Vapi({
    baseURL: process.env.VUE_APP_AVISOS_API_BASE_URL,
    state: {
        avisos: [],
        aviso: {},
    },
})
    .get({
        action: 'listAvisosByEstado',
        property: 'avisos',
        path: '/avisos?tipo=estado',
        queryParams: true,
        headers: authorization,
        onSuccess: setAvisos,
    })
    .get({
        action: 'listAvisos',
        property: 'avisos',
        path: '/avisos',
        queryParams: true,
        headers: authorization,
        onSuccess: setAvisos,
    })
    .get({
        action: 'reporteFunerarias',
        property: 'avisos',
        path: '/reportes/funerarias',
        queryParams: true,
        headers: authorization,
    })
    .get({
        action: 'reporteCreadores',
        property: 'avisos',
        path: '/reportes/creadores',
        queryParams: true,
        headers: authorization,
    })
    .get({
        action: 'getAviso',
        property: 'aviso',
        path: ({ id }) => `/avisos/${id}`,
        headers: authorization,
    })
    .post({
        action: 'postAviso',
        property: 'aviso',
        path: '/avisos',
        headers: authorization,
    })
    .put({
        action: 'updateAviso',
        property: 'aviso',
        path: ({ id }) => `/avisos/${id}`,
        headers: authorization,
    })
    .post({
        action: 'publishAviso',
        property: 'aviso',
        path: ({ id }) => `/avisos/${id}/publish`,
        headers: authorization,
    })
    .getStore({
        namespaced: true,
        // createStateFn: true,
    });

avisos.mutations = {
    ...make.mutations(['aviso']),
    ...avisos.mutations,
};

export default avisos;
