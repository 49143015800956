<template>
  <v-fade-transition v-if="isReady"
                     mode="out-in">
    <router-view />
  </v-fade-transition>
  <app-init v-else />
</template>

<script>
  import { get, sync } from 'vuex-pathify';
  import AppInit from '@/components/AppInit';
  import { defaultRoute } from '@/router';
  // Styles
  import '@/styles/overrides.scss';

  export default {
    name: 'App',

    components: {
      AppInit,
    },

    metaInfo: {
      title: 'El Observador Fúnebres',
      titleTemplate: '%s | El Observador Fúnebres',
      htmlAttrs: { lang: 'es' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },

    computed: {
      isReady: get('app/isReady'),
      loginRedirect: sync('app/loginRedirect'),
      originalHistoryCount: sync('app/originalHistoryCount'),
      isLoggedIn: get('cognito/isLoggedIn'),
    },

    watch: {
      isReady(val) {
        if (val && this.isLoggedIn) {
          if (this.loginRedirect) {
            const redirect = this.loginRedirect;
            this.loginRedirect = null;
            this.$router.push(redirect);
          } else {
            this.$router.push(defaultRoute);
          }
        }
      },
    },

    mounted() {
      // Como el máximo de historial son 50 páginas, marco el largo del history original en 49 como máximo.
      // Esto causará que si al entrar en la app por primera vez ya hay 50 elementos en el history, se considerará
      // que no es la primera página, y el botón de volver en la lista de avisos llevará a la página anterior (la nro 49),
      // sea cual fuere que sea (podría incluso ser una página externa).
      this.originalHistoryCount = Math.min(window.history.length, 49);
    },
  };
</script>
