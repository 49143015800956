import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { layout, route } from './util';

Vue.use(VueRouter);

export const defaultRoute = '/';

const routes = [
    layout('Default', [
            route('Dashboard', null, '/'),
            route('Avisos', null, 'avisos', route => ({ estado: route.query.estado })),
            route('Aviso', null, 'avisos/:id', true),
            route('IngresoAviso', null, 'aviso'),
            route('BuscadorDeAvisos', null, 'buscador-de-avisos'),
            route('Reportes', null, 'reportes'),
        ],
        '',
        { requiresAuth: true },
    ),
    layout('Default', [
            route('Usuarios', null, 'usuarios'),
            route('ABMCementerios', null, 'cementerios'),
            route('ABMVelatorios', null, 'velatorios'),
            route('ABMFunerarias', null, 'funerarias'),
            route('Configuracion', null, 'general'),
        ],
        '/config',
        { requiresAuth: true, groups: ['Admins'] },
    ),
    layout('Basic', [
        route('Login', null, 'login'),
    ]),
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.get('cognito/isLoggedIn');
    const isLoginOrRegister = (to.path === '/login' || to.path === '/register');

    if (isLoginOrRegister && isAuthenticated) {
        next(defaultRoute);
        return;
    }

    const requiresAuth = to.matched.some(rr => rr.meta.requiresAuth);
    if (requiresAuth) {
        if (!isAuthenticated) {
            store.set('app/loginRedirect', to);
            next({ path: '/login' });
            return;
        }

        const requiresGroup = to.matched.some(rr => rr.meta.groups && rr.meta.groups.length > 0);
        if (requiresGroup) {
            const userGroups = store.get('cognito/userGroups') || [];
            const routeGroupsArray = to.matched.map(rr => rr.meta?.groups || []).filter(gs => gs.length > 0);

            if (!routeGroupsArray.every(routeGroups => routeGroups.some(rg => userGroups.includes(rg)))) {
                // forbidden
                next(new Error('forbidden'));
                return;
            }
        }
    }
    next();
});

// TODO considerar agregar router.onError() para cubrir casos 403

export default router;
