import Vue from 'vue';
import Vuex from 'vuex';
import pathify from '@/plugins/vuex-pathify';
import attachCognitoModule from './modules/cognito';

// Modules
import * as modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules,
    plugins: [
        pathify.plugin,
    ],
    strict: true,
});

attachCognitoModule(store, {
    region: process.env.VUE_APP_AWS_REGION,
    identityPoolRegion: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID, // optional
}, 'cognito');

store.dispatch('app/init')
    // eslint-disable-next-line no-console
    .finally(() => console.log('app/init finished'));

store.dispatch('cognito/fetchSession')
     .finally(() => store.set('app/isReady', true));

export default store;
