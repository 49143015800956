// Pathify
import { make } from 'vuex-pathify';

const state = {
    version: process.env.VUE_APP_VERSION,
    isReady: false,
    loginRedirect: null,
    sidebar: null,
    mini: false,
    originalHistoryCount: 0,
    sidebarItems: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
        // { title: '', icon: '', to: '' },
        { title: 'Nuevo Aviso', icon: 'mdi-text-box-plus-outline', to: '/aviso' },
        {
            title: 'Avisos',
            icon: 'mdi-text-box-multiple-outline',
            items: [
                { title: 'Ingresados', icon: '', to: '/avisos?estado=INGRESADO', exact: true },
                { title: 'Aprobados', icon: '', to: '/avisos?estado=APROBADO', exact: true },
                { title: 'Publicados', icon: '', to: '/avisos?estado=PUBLICADO', exact: true },
                { title: 'Confirmados', icon: '', to: '/avisos?estado=CONFIRMADO', exact: true },
                { title: 'Facturados', icon: '', to: '/avisos?estado=FACTURADO', exact: true },
                { title: 'Rechazados', icon: '', to: '/avisos?estado=RECHAZADO', exact: true },
            ],
        },
        { title: 'Buscador de Avisos', icon: 'mdi-magnify', to: '/buscador-de-avisos' },
        { title: 'Reportes', icon: 'mdi-file-table', to: '/reportes' },
        {
            title: 'Configuración',
            icon: 'mdi-cog',
            items: [
                { title: 'Usuarios', icon: 'mdi-account-group', to: '/config/usuarios' },
                { title: 'Cementerios', icon: 'mdi-grave-stone', to: '/config/cementerios' },
                { title: 'Velatorios', icon: 'mdi-coffin', to: '/config/velatorios' },
                { title: 'Funerarias', /* icon: 'mdi-home', */ to: '/config/funerarias' },
                { title: 'Config. Gral.', icon: 'mdi-cog', to: '/config/general' },
            ],
            groups: ['Admins'],
        },
    ],
};

const mutations = make.mutations(state);

const actions = {
    ...make.actions(state),

    // eslint-disable-next-line no-unused-vars
    init: async ({ dispatch }) => {
        // init app
    },
};

const getters = {
    historyLength: (state) => () => window.history.length - state.originalHistoryCount,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
