<template>
  <v-app>
    <v-container style="height: 400px;">
      <v-row class="fill-height"
             align-content="center"
             justify="center">
        <v-col class="text-subtitle-1 text-center"
               cols="12">
          Iniciando...
        </v-col>
        <v-col cols="6">
          <!-- oscuro: 007a63   claro: 00a858 -->
          <v-progress-linear color="#007a63"
                             indeterminate
                             rounded
                             height="10" />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'AppInit',
  };
</script>

<style scoped>

</style>
